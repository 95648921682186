/* eslint-disable react/jsx-no-target-blank */
import { Twitter } from '@mui/icons-material'
import { Box, Paper, Grid, styled, Typography } from '@mui/material'
import portrait from '../images/matthew_bevan_square.jpg'
import { GithubLogo64, LinkedInLogo, TwitterLogo } from './logos'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  lineHeight: '20px',
  padding: '50px',
  paddingTop: ''
}))

export const AboutMe = () => {
  const aboutText1 = `I am a software developer with a passion for programming and problem solving. After completing a Bachelor of Computer Science, I jumped at the chance to kickstart my 
  professional career as a Full Stack Developer working on enterprise email suppression list management software, and quickly found a home building in the Web 2.0 space. Frustrated with the 
  inherent centralisation and lack of individual ownership across an internet owned by the Big Five, the concept of blockchain technology piqued my interest in 2021. Blockchain and the
  crypto space opens a world of opportunity for decentralisation, self-sovereign ownership and scaling like never before that I am passionate to be a part of since joining Labrys in 2022. 
  At Labrys I work as a Full Stack Software Developer, tackling problems in both the Web 2.0 and Web 3.0 world, building web applications and smart contracts alongside some of the best
  developers in the crypto space today.`

  const aboutText2 = `Feel free to contact me on LinkedIn or Twitter.`

  return (
    <Box id='about-me'>
      <Box
        sx={{
          p: 4,
          margin: 'auto',
          maxWidth: '1400px'
        }}
      >
        <Grid container>
          <Grid item>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { md: '2fr' },
                gap: 4,
                textAlign: 'center',
                alignSelf: 'center'
              }}
            >
              <Item elevation={18}>
                <Typography variant='h4'>About me</Typography>
                <Box mt={4} />
                <Typography
                  variant='body1'
                  sx={{
                    fontFamily: 'Lato',
                    fontWeight: 'bolder',
                    fontSize: 18
                  }}
                >
                  {aboutText1}
                </Typography>{' '}
                <Box mt={4} />
                <Typography
                  variant='body1'
                  sx={{
                    fontFamily: 'Lato',
                    fontWeight: 'bolder',
                    fontSize: 18
                  }}
                >
                  {aboutText2}
                </Typography>{' '}
                <Box mt={4} />
                <a href='https://www.linkedin.com/in/mfbevan/' target='_blank'>
                  <LinkedInLogo />
                </a>
                <Box sx={{ display: 'inline', marginRight: 4 }} />
                <a href='https://github.com/mfbevan' target='_blank'>
                  <GithubLogo64 />
                </a>
                <Box sx={{ display: 'inline', marginRight: 4 }} />
                <a href='https://twitter.com/mfbevan' target='_blank'>
                  <TwitterLogo />
                </a>
              </Item>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
